<template>
  <el-main>
    <div class="scanCodeGun-img">
      <div class="title">扫码枪推荐：得力扫码枪、霍尼韦尔扫码枪</div>
      <ul>
        <li @click="selectScanCodeGun(index)" v-for="(item, index) in img_list" :key="index">
          <div class="img" :style="{ 'background-image': 'url(' + item.img + ')', 'background-size': '100% 100%' }"></div>
          <el-image v-show="item.is_select" :src="require('@/assets/image/hardware/gou@2x.png')"></el-image>
          <p>{{ item.name }}</p>
        </li>
      </ul>
      <el-button @click="go" type="primary">官网购买</el-button>
    </div>
    <div class="connect-methods">
      <div class="title">连接方式</div>
      <div class="content">
        <p>1、有线激光扫描枪，可对接通过电脑端USB接口接入的扫码枪</p>
        <p>2、无线激光扫描枪插上接收器，即可使用</p>
        <el-button type="text" class="tips">备注:条形码扫码枪和二维码扫码枪都可以支持，无特殊型号要求，电脑可接入即可使用。</el-button>
      </div>
    </div>
  </el-main>
</template>
<script>
import img1 from '@/assets/image/hardware/pic01@2x.png';
import img2 from '@/assets/image/hardware/pic02@2x.png';
import img3 from '@/assets/image/hardware/pic03@2x.png';
import img4 from '@/assets/image/hardware/pic04@2x.png';
import img5 from '@/assets/image/hardware/pic05@2x.png';
export default {
  data() {
    return {
      img_list: [
        {
          name: '得力有线扫码枪',
          path: 'https://item.jd.com/7640979.html',
          img: img1,
          is_select: !0,
        },
        {
          name: '得力无线扫码枪',
          path: 'https://item.jd.com/100009974367.html',
          img: img2,
          is_select: !1,
        },
        {
          name: '霍尼韦尔有线扫码枪',
          path: 'https://item.jd.com/5567154.html',
          img: img3,
          is_select: !1,
        },
        {
          name: '霍尼韦尔扫码枪',
          path: 'https://item.jd.com/100021942374.html',
          img: img4,
          is_select: !1,
        },
        {
          name: '驰腾扫码枪',
          path: 'https://item.jd.com/100010459608.html',
          img: img5,
          is_select: !1,
        },
      ],
    };
  },
  methods: {
    selectScanCodeGun(index) {
      this.img_list.map(item => (item.is_select = !1));
      this.img_list[index].is_select = !this.img_list[index].is_select;
    },
    go() {
      let img_list = this.img_list.filter(item => item.is_select);
      window.open(img_list[0].path, '_blank');
    },
  },
};
</script>
<style lang="less" scoped>
.el-main {
  background: #fff;
  font-size: 14px;
  .scanCodeGun-img {
    .title {
      padding-left: 10px;
      line-height: 36px;

      margin-bottom: 15px;
      background: #f7f8fa;
    }
    ul {
      display: flex;
      margin-bottom: 40px;
      li {
        margin-right: 15px;
        width: 148px;
        text-align: center;
        position: relative;
        .el-image {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 10;
          width: 29px;
          height: 29px;
        }
        .img {
          width: 100%;
          height: 148px;
          margin-bottom: 5px;
        }
      }
    }
  }
  .connect-methods {
    margin-top: 48px;
    .title {
      padding-left: 10px;
      line-height: 36px;
      margin-bottom: 15px;
      background: #f7f8fa;
    }
    .content{
        margin-top:14px;
        p{
            margin-bottom:10px;
        }
    }
  }
}
</style>
